import { ListGroup, ListGroupItem, Row, Col, Form } from "react-bootstrap"
import { useState, useEffect } from "react"
import { enterScore } from "../../../../actions/matches";
import { useDispatch } from "react-redux";
import { ButtonGroup, Button } from "@mui/material";

export const BracketPlayGroup = ({group, index, isQualified, match, readOnly, disabled}) => {

  const [player1Score, setPlayer1Score] = useState(match.player_1_score)
  const [player2Score, setPlayer2Score] = useState(match.player_2_score)

  const player1 = match.player1
  const player2 = match.player2

  const dispatch = useDispatch()

  const handleChangeFirstScore = (event) => {
    const enteredScore = event.target.value
    if (!isNaN(enteredScore) && enteredScore < 4) {
      setPlayer1Score(enteredScore)
    }
  }

  const handleChangeSecondScore = (event) => {
    const enteredScore = event.target.value
    if (!isNaN(enteredScore) && enteredScore < 4) {
      setPlayer2Score(enteredScore) 
    }
  }

  const setScore = (player1Score, player2Score) => {
    setPlayer1Score(player1Score)
    setPlayer2Score(player2Score)
    dispatch(enterScore(match.id, player1Score, player2Score))
  }

  useEffect(() =>
  {
    if (player1Score && player2Score && (player1Score < player2Score || player1Score > player2Score) && !match.finished && !readOnly)
      dispatch(enterScore(match.id, player1Score, player2Score))
  },
  [player1Score, player2Score])

  return (
    <>
      <ListGroup className="group">
        <ListGroupItem className={isQualified(player1) ? 'selected' : ''}>
          <Row>
            <Col md={8} xs={7} className="medium-text">
              {player1.name || "BYE"}
            </Col>
            <Col md={4} xs={5} className="medium-text">
              <Form.Control 
                size='lg'
                readOnly={readOnly}
                className={`text-center ${match.finished ? (isQualified(player1) ? 'selected' : 'no-border transparent') : 'transparent'}`}
                onChange={handleChangeFirstScore}
                defaultValue={match.player_1_score}
                maxLength={1}
                disabled={disabled}/>
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className={isQualified(player2) ? 'selected' : ''}>
          <Row>
            <Col md={8} xs={7} className="medium-text">
              {player2.name || "BYE"}
            </Col>
            <Col md={4} xs={5}>
              <Form.Control
                size='lg'
                readOnly={readOnly}
                className={`text-center ${match.finished ? (isQualified(player2) ? 'selected' : 'no-border transparent') : 'transparent'}`}
                onChange={handleChangeSecondScore}
                defaultValue={match.player_2_score}
                disabled={disabled}/>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
      {!disabled && !readOnly && !(player1.bye || player2.bye) && <ButtonGroup className="center-content-h">
        <Button onClick={() => setScore(3,0)}>3-0</Button>
        <Button onClick={() => setScore(3,1)}>3-1</Button>
        <Button onClick={() => setScore(3,2)}>3-2</Button>
        <Button onClick={() => setScore(2,3)}>2-3</Button>
        <Button onClick={() => setScore(1,3)}>1-3</Button>
        <Button onClick={() => setScore(0,3)}>0-3</Button>
      </ButtonGroup>
}
    </>
  );
}