import { SERVER_URL } from '../server_config';

export const QUALIFY_PLAYER_STARTED = 'QUALIFY_PLAYER_STARTED';
export const QUALIFY_PLAYER_SUCCEEDED = 'QUALIFY_PLAYER_SUCCEEDED';
export const QUALIFY_PLAYER_FAILURE = 'QUALIFY_PLAYER_FAILURE';
export const DISQUALIFY_PLAYER_STARTED = 'DISQUALIFY_PLAYER_STARTED';
export const DISQUALIFY_PLAYER_SUCCEEDED = 'DISQUALIFY_PLAYER_SUCCEEDED';
export const DISQUALIFY_PLAYER_FAILURE = 'DISQUALIFY_PLAYER_FAILURE';
export const ADD_PLAYER_FAILED = 'ADD_PLAYER_FAILED'
export const ADD_PLAYER_SUCCEEDED = 'ADD_PLAYER_SUCCEEDED'
export const ADD_PLAYER_STARTED = 'ADD_PLAYER_STARTED'
export const MOVE_PLAYER_STARTED = 'MOVE_PLAYER_STARTED'
export const MOVE_PLAYER_SUCCEEDED = 'MOVE_PLAYER_SUCCEEDED'
export const MOVE_PLAYER_FAILED = 'MOVE_PLAYER_FAILED'
export const DELETE_PLAYER_SUCCEEDED = 'DELETE_PLAYER_SUCCEEDED'
export const DELETE_PLAYER_FAILED = 'DELETE_PLAYER_FAILED'

export const qualifyPlayerStarted = () => ({
  type: QUALIFY_PLAYER_STARTED
})

export const qualifyPlayerSucceeded = qualifiedPlayer => ({
  type: QUALIFY_PLAYER_SUCCEEDED,
  qualifiedPlayer
})

export const qualifyPlayerFailed = error => ({
  type: QUALIFY_PLAYER_FAILURE,
  error
})

export const disqualifyPlayerStarted = () => ({
  type: DISQUALIFY_PLAYER_STARTED
})

export const disqualifyPlayerSucceeded = disqualifiedPlayer => ({
  type: DISQUALIFY_PLAYER_SUCCEEDED,
  disqualifiedPlayer
})

export const disqualifyPlayerFailed = error => ({
  type: DISQUALIFY_PLAYER_FAILURE,
  error
})

export const addPlayerStarted = () => ({
  type: ADD_PLAYER_STARTED,
})

export const addPlayerFailed = error => ({
  type: ADD_PLAYER_FAILED,
  error,
})

export const addPlayerSucceeded = event => ({
  type: ADD_PLAYER_SUCCEEDED,
  response: event
})

export const movePlayerStarted = () => ({
  type: MOVE_PLAYER_STARTED,
})

export const movePlayerSucceeded = data => ({
  type: MOVE_PLAYER_SUCCEEDED,
  data
})

export const movePlayerFailed = error => ({
  type: MOVE_PLAYER_FAILED,
  error
})

export const deletePlayerSucceeded = data => ({
  type: 'DELETE_PLAYER_SUCCEEDED',
  data
})

export const deletePlayerFailed = error => ({
  type: 'DELETE_PLAYER_FAILED',
  error
})

export const qualifyPlayer = (playerInstance) => {
  return async (dispatch) => {

    dispatch(qualifyPlayerStarted());
    const url = `${SERVER_URL}/players/player_instances/qualify/${playerInstance.id}`

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(qualifyPlayerSucceeded(playerInstance));
    } catch (error){
      dispatch(qualifyPlayerFailed(error));
    }
  }
}

export const disqualifyPlayer = (player_instance) => {
  return async (dispatch) => {

    dispatch(disqualifyPlayerStarted());
    const url = `${SERVER_URL}/players/player_instances/disqualify/${player_instance.id}`

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      dispatch(disqualifyPlayerSucceeded(player_instance));
    } catch (error){
      console.log(error)
    }
  }
}

export const addPlayer = (player, destination, adminAddedPlayer) => {
  return async dispatch => {
    dispatch(addPlayerStarted())

    const body = {
      player: player,
      destination: destination,
      adminAddedPlayer: adminAddedPlayer,
    }

    fetch(`${SERVER_URL}/players`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.status === 409) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => { 
      dispatch(addPlayerSucceeded(response.data))
    })
    .catch(error => {
      dispatch(addPlayerFailed(error.message))
    });
  }
}


export const movePlayer = (player, target) => {
  return async (dispatch) => {
    const body = {
      'playerId': player.id,
      'target': target, 
    }

    const eventId = player.eventId
    dispatch(movePlayerStarted());
    
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/move_player`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsed_response = await response.json(); // Parse the response body as JSON
      dispatch(movePlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(movePlayerFailed(error));
    }
  }
}

export const addTestPlayers = (nrOfPlayers, eventId) => {
  return async (dispatch) => {
    const body = {nrOfPlayers: nrOfPlayers}
    
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/add_test_players`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const parsed_response = await response.json();
      dispatch(addPlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(addPlayerFailed(error.message));
    }
  };
}

export const deletePlayer = (playerId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/players/${playerId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const parsed_response = await response.json();
      console.log(parsed_response)
      dispatch(deletePlayerSucceeded(parsed_response.data));
    } catch (error) {
      dispatch(deletePlayerFailed(error.message));
    }
  };
}
