import React from 'react';
import { Carousel, Col } from 'react-bootstrap';
import { useState } from 'react';
import { PlayGroup } from '../event_dashboard/play_tab/group';
import { BracketPlayGroup } from '../event_dashboard/play_tab/bracket_group';

const CurrentRoundInfo = ({ finished_event, rounds }) => {
  const [index, setIndex] = useState(rounds.length - 1);
  
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const isQualified = (player) => {
    return player.qualified;
  }

  return (
    <Col xs={12} md={4} className="detailed-event-column">
      <div className="large-text bold primary-color border-bottom margin-bottom-1">Faza de joc</div>
      <Carousel className='custom-carousel' activeIndex={index} onSelect={handleSelect} variant='dark' interval={null}>
        {rounds.map((round, index) => (
          <Carousel.Item key={index}>
            <div className='slide-container'>
              <div className='large-text bold primary-color'>{index === 0 ? 'Grupe' : 'Runda ' + (index + 1)}</div>
              <p>{round.index}</p>
              {round.groups.map((group, index) => 
                  group.matches.length > 1 ?
                  <PlayGroup group={group} index={index + 1} isQualified={isQualified} disabled={false} readOnly={true}/>
                  :
                  <BracketPlayGroup match={group.matches[0]} readOnly={true} key={index} group={group} isQualified={isQualified} disabled={true}/>
              )}
            </div>
          </Carousel.Item>
        ))}
        { finished_event && 
          <Carousel.Item>
            <div className='slide-container'>
              <div className='xxl-text bold primary-color margin-bottom-1'>Castigator</div>
              <div className='xxl-text bold'>{rounds[rounds.length-1].qualified_players[0].name}</div>
            </div>
          </Carousel.Item>
        }
      </Carousel>
    </Col>
  );
};

export default CurrentRoundInfo;
