import { Row, Col, Form, Button } from "react-bootstrap"
import { CountyFilter } from "../shared/county_filter"
import { useState, useEffect } from "react"
import { fetchEvents } from "../../actions/events"
import { useDispatch } from "react-redux"
import { SwipeableDrawer } from "@mui/material"
import IconButton from "../shared/icon_button"

export const MobileEventFilters = () => {

  const [county, setCounty] = useState('');
  const [name, setName] = useState('');
  const [ startDate, setStartDate ] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    const futureDate = new Date();
    futureDate.setFullYear(futureDate.getFullYear() + 5);
    return futureDate;
  });
  const [ stage, setStage ] = useState('Orice Stagiu');
  const [ filtersOpen, setFiltersOpen] = useState(false);

  const STAGES = {
    all: 'Orice Stagiu',
    registration: 'Inscrieri Deschise',
    playing: 'In Desfasurare',
    finished: 'Incheiat',
  };
  

  const filters = { name, county, startDate, endDate, stage }
  const dispatch = useDispatch()

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value)
  }

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value)
  }
  
  const handleSetStage = (event) => {
    setStage(event.target.value)
  }

  const handleSubmit = () => {
    dispatch(fetchEvents(filters));
    setFiltersOpen(false);
  }

  useEffect(() => {
    dispatch(fetchEvents(filters));
  }, [filters]);

  return(
    <>
      <div className="center-content-h">
        <IconButton height={24} width={48} icon={'down'} onClick={() => {setFiltersOpen(!filtersOpen)}}></IconButton>
      </div>
      <SwipeableDrawer
        anchor={'top'}
        open={filtersOpen}
        onClose={() => {setFiltersOpen(false)}}
        onOpen={() => {setFiltersOpen(true)}}
      >
        <Col className='margin-1'>

          <Form.Label className='medium-text'>Nume Eveniment</Form.Label>
          <Form.Control onChange={handleNameChange} defaultValue={name} className="mobile-filter-field"></Form.Control>
          <CountyFilter labelClass='medium-text' county={county} setCounty={setCounty} style="mobile-filter-field"></CountyFilter>
          <Form.Label className='medium-text'>Stagiu</Form.Label>
          <Form.Select onChange={handleSetStage} className="mobile-filter-field">
            {Object.keys(STAGES).map((stage_name) => {
            return(
              <option key={stage_name} value={stage_name}>
                {STAGES[stage_name]}
              </option>
            )
            })}
          </Form.Select>

          <Form.Label className='medium-text'>Dupa</Form.Label>
          <Form.Control type="date" onChange={handleChangeStartDate} className="mobile-filter-field"></Form.Control>

          <Form.Label className='medium-text' >
            Inainte de</Form.Label>
          <Form.Control type="date" className="mobile-filter-field" onChange={handleChangeEndDate}></Form.Control>
          <Button className="primary margin-top-1" onClick={handleSubmit}>Aplica Filtre</Button>
        </Col>
      </SwipeableDrawer>
    </>
  )
}