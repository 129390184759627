import IconButton from "../../../shared/icon_button"
import { useDispatch } from "react-redux"
import { movePlayer, deletePlayer} from "../../../../actions/players"

export const Player = ({player,  variant, disabled}) => {

  var firstIcon 
  var secondIcon

  var firstHandle
  var secondHandle

  const dispatch = useDispatch()

  const handleMoveLeft = () => {
    dispatch(movePlayer(player, 'left'))
  }

  const handleMoveRight = () => {
    dispatch(movePlayer(player, 'right'))
  }

  const handleMoveDown = () => {
    dispatch(movePlayer(player, 'down'))
  }

  const removePlayer = () => {
    dispatch(deletePlayer(player.id))
  }

  switch (variant) {
    case 'left':
      firstIcon = 'close';
      secondIcon = 'hourglass';
      firstHandle = handleMoveDown
      secondHandle = handleMoveRight
      break;
    case 'right':
      firstIcon = 'close';
      secondIcon = 'check';
      firstHandle = handleMoveDown
      secondHandle = handleMoveLeft
      break;
    case 'down':
      firstIcon = 'check';
      secondIcon = 'hourglass';
      firstHandle = handleMoveLeft
      secondHandle = handleMoveRight
      break;
    default:
      break;
  }

  return (
    <tr className="center-content-text-table">
      {variant === 'left' && <td>{player.position}</td>}
      <td>{player.name}</td>
      <td>{player.phone}</td>
      <td>{player.city}</td>
      <td>
        <div className="d-flex justify-content-center">
          <IconButton height={18} width={18} icon={firstIcon} onClick={firstHandle} disabled={disabled}></IconButton>
          <IconButton height={18} width={18} transparent={true} icon={secondIcon} onClick={secondHandle} disabled={disabled}></IconButton>
          <IconButton height={18} width={18} transparent={true} icon={'delete'} onClick={removePlayer} disabled={disabled}></IconButton>
        </div>
      </td>
    </tr>
  )
}