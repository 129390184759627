import { Alert, Button } from "react-bootstrap"
import { useSelector } from "react-redux"

export const ErrorAlert = ({errorSelector, onClose}) => {
  const error = useSelector(errorSelector)

  return (
    <Alert show={error} variant="danger">
      <p className="bold medium-text">
        {error}
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={onClose} variant="outline-danger">
          Am inteles
        </Button>
      </div>
    </Alert>
  )
}


