import { Row, Col, Container } from "react-bootstrap"

export const Footer = () => {
  return(
    <>
    <Container fluid className="footer-container">
      <Row className='footer'>
        <Col className="xs-text margin-1 bold no-padding">
          Contact: dcercega@gmail.com
        </Col>
        <Col className="xs-text no-padding">
          <a className='pull-right margin-1 header-link' href="/roadmap">Despre top-spin.ro</a>
        </Col>
      </Row>
    </Container>
    </>
  )
}