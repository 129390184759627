import { Button, ListGroup, Row, Col, Modal, Form} from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selfRegisterPlayer } from '../../actions/events';
import { selectAuthenticated, selectUser} from '../../selectors/users';
import { Drawer } from '@mui/material';

const MobileEvent = ({key, event}) => {

  const REGISTRATION = 'registration';
  const FINISHED = 'finished';
  const BRACKETS = 'brackets';
  const MATCHUPS = 'matchups';
  const GROUPS = 'groups';

  const [show, setShow] = useState(false);
  const [contactHidden, setContactHidden] = useState(true);

  const isAuthenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)

  const currentUserRegistered = user ? event.players.some(player => player.phone === user.phone) : false;

  const [name, setName] = useState(isAuthenticated ? user.name : '');
  const [phone, setPhone] = useState(isAuthenticated ? user.phone : '');
  const [city, setCity] = useState(isAuthenticated ? user.city : '');

  const [showPlayers, setShowPlayers] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);

  const dispatch = useDispatch()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handleCityChange = (event) => {
    setCity(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(selfRegisterPlayer({name, phone, city, eventId: event.id}));
    handleClose();
  }

  const SignUpButton = () => {
    if (event.stageId === 1) {
      if(currentUserRegistered)
        return(<Button disabled className='sign-up-button medium-text'>Inscris</Button>)
      else
        if(event.registration_closed)
          return(<Button disabled className='sign-up-button medium-text' onClick={handleShow}>Inscrieri Inchise</Button>)
        else
          return(<Button className='sign-up-button medium-text' onClick={handleShow}>Inscriere</Button>)
    }
    else if (event.stageId >= 2 && event.stageId < 5)
      return(<Button disabled className='sign-up-button medium-text' onClick={handleShow}>Faza de joc</Button>)
    else if (event.stageId === 5)
      return(<Button disabled className='sign-up-button medium-text' onClick={handleShow}>Incheiat</Button>)
  }
  
  return (
    <div className='mobile-event-container'>
      <div className='primary mobile-name-organizer'>
        <div className='xxl-text extra-bold margin-bottom-d5 border-bottom-1'>{event.name}</div>
        <div className='medium-text bold'>{event.customer.name} - {event.date.slice(0,10)} | {event.date.slice(11,16)}</div>
        <div className='medium-text bold'>{event.location} {event.county}</div>
      </div>
      <Row className='padding-1'>
        <Col className='center-content'>
          <SignUpButton/>
        </Col>
        <Col className='center-content'>
          {
            contactHidden
            ?
            <Button variant="outline-dark" className='medium-text contact-button' onClick={() => {setContactHidden(false)}}>Contact</Button> 
            :
            <ListGroup>
              <ListGroup.Item action onClick={() => {setContactHidden(true)}}>
                {event.customer.managers[0].name}
              </ListGroup.Item>
              <ListGroup.Item action onClick={() => {setContactHidden(true)}}>
                {event.customer.managers[0].phone}
              </ListGroup.Item>
            </ListGroup>
          }
        </Col>
      </Row>
      <Row className='mobile-event-player-row'>
        <Col className='mobile-event-player-col'>
          <Button className='mobile-players-drawer-button' onClick={() => event.confirmedPlayers.length > 0 && setShowPlayers(true)}>Jucatori: {event.confirmedPlayers.length} / {event.max_players}</Button>
          <Drawer
            anchor='left'
            open={showPlayers}
            onClose={() => setShowPlayers(false)}>
            <div className='padding-1 large-text'>
              <div className='bold border-bottom-1 margin-bottom-1'>Jucatori: {event.confirmedPlayers.length} / {event.max_players}</div>
              {event.confirmedPlayers.map((player, index) => (
                <div>{player.name} - {player.city}</div>
              ))}
            </div>

          </Drawer>
        </Col>
        <Col className='mobile-event-player-col'>
          <Button className='mobile-players-drawer-button' onClick={() => event.waitlistedPlayers.length && setShowWaitlist(true)}>Lista de asteptare: {event.waitlistedPlayers.length}</Button>
          <Drawer
            anchor='right'
            open={showWaitlist}
            onClose={() => {setShowWaitlist(false)}}>
            <div className='padding-1 large-text'>
              <div className='bold border-bottom-1 margin-bottom-1'>Lista de asteptare: {event.waitlistedPlayers.length}</div>
              {event.waitlistedPlayers.map((player, index) => (
                <div>{index+1}.{player.name} - {player.city}</div>
              ))}
            </div>
          </Drawer>
        </Col>
      </Row>

      <Button href={`/events/${event.id}`} className='event-col-button center-content medium-text'>
        Detalii
      </Button>
      
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Inscriere {event.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label className='margin-bottom-1'>Nume</Form.Label>
            <Form.Control defaultValue={name} onChange={handleNameChange}></Form.Control>
            <Form.Label className='margin-bottom-1 margin-top-1'>Numar de telefon</Form.Label>
            <Form.Control defaultValue={phone} onChange={handlePhoneChange}></Form.Control>
            <Form.Label className='margin-bottom-1 margin-top-1'>Oras</Form.Label>
            <Form.Control defaultValue={city} onChange={handleCityChange}></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary" onClick={handleSubmit}>
            Inscriere
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MobileEvent