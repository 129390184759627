import { Row, Col, Form, Button } from "react-bootstrap"
import { CountyFilter } from "../shared/county_filter"
import { useState, useEffect } from "react"
import { fetchEvents } from "../../actions/events"
import { useDispatch } from "react-redux"

export const EventFilters = () => {

  const [county, setCounty] = useState('');
  const [name, setName] = useState('');
  const [ startDate, setStartDate ] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    const futureDate = new Date();
    futureDate.setFullYear(futureDate.getFullYear() + 5);
    return futureDate;
  });
  const [ stage, setStage ] = useState('Orice Stagiu');

  const STAGES = {
    all: 'Orice Stagiu',
    registration: 'Inscrieri Deschise',
    playing: 'In Desfasurare',
    finished: 'Incheiat',
  };
  

  const filters = { name, county, startDate, endDate, stage }
  const dispatch = useDispatch()

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value)
  }

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value)
  }
  
  const handleSetStage = (event) => {
    setStage(event.target.value)
  }

  useEffect(() => {
    dispatch(fetchEvents(filters));
  }, [filters]);

  return(
    <div className='home-filters-container'>
      <Row className='padding-1'>
        <Col md={3}>
          <Form.Label className='medium-text'>Nume Eveniment</Form.Label>
          <Form.Control onChange={handleNameChange} defaultValue={name}></Form.Control>
        </Col>
        <Col>
          <CountyFilter labelClass='medium-text' county={county} setCounty={setCounty}></CountyFilter>
        </Col>
        <Col>
          <Form.Label className='medium-text'>Stagiu</Form.Label>
          <Form.Select onChange={handleSetStage}>
            {Object.keys(STAGES).map((stage_name) => {
            return(
              <option key={stage_name} value={stage_name}>
                {STAGES[stage_name]}
              </option>
            )
            })}
          </Form.Select>
        </Col>
        <Col>
          <Form.Label className='medium-text'>Dupa</Form.Label>
          <Form.Control type="date" onChange={handleChangeStartDate}></Form.Control>
        </Col>
        <Col>
          <Form.Label className='medium-text'>
            Inainte de</Form.Label>
          <Form.Control type="date" onChange={handleChangeEndDate}></Form.Control>
        </Col>
      </Row>
    </div>
  )
}