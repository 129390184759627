import {
  FETCH_EVENTS_STARTED,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_EVENTS_FAILED,
  FETCH_EVENT_DATA_STARTED,
  FETCH_EVENT_DATA_SUCCEEDED,
  FETCH_EVENT_DATA_FAILED,
  SELF_REGISTER_PLAYER_SUCCEEDED,
  SELF_REGISTER_PLAYER_FAILED,
  CHANGE_FILTERS,
  RESET_ERROR_STATE,
} from '../../actions/events'
import { DISQUALIFY_PLAYER_SUCCEEDED, QUALIFY_PLAYER_SUCCEEDED } from '../../actions/players'
import { END_ROUND_FAILED, END_ROUND_STARTED, END_ROUND_SUCCEEDED } from '../../actions/rounds'
import { ENTER_SCORE_SUCCEEDED } from '../../actions/matches'

const initialState = {
  status: 'uninitialized',
  events: [],
  error: null,
  current_event: {
    event: null,
    current_round: null,
    error: null,
    filters: null,
    status: 'uninitialized',
    managers: [],
  },
}

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_EVENTS_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        events: action.events,
      }
    }
    case FETCH_EVENTS_FAILED: {
      return {
        ...state,
        status: 'failed',
        events: [],
        error: action.error
      }
    }
    case FETCH_EVENT_DATA_STARTED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'loading'
        }
      }
    }
    case FETCH_EVENT_DATA_SUCCEEDED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'success',
          eventData: action.response.event,
          current_round: action.response.event.lastRound,
          managers: action.response.managers,
        }
      }
    }
    case FETCH_EVENT_DATA_FAILED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'failed',
          eventData: null,
          current_round: null,
          error: action.error
        }
      }
    }
    case SELF_REGISTER_PLAYER_SUCCEEDED: {
      console.log(action)
      const { eventData } = action;
      
      const eventIndex = state.events.findIndex(event => event.id === eventData.id);
    
      if (eventIndex !== -1) {
        const updatedEvents = [...state.events];
        updatedEvents[eventIndex] = eventData;
    
        return {
          ...state,
          events: updatedEvents,
          current_event: {
            ...state.current_event,
            status: 'success',
            eventData: eventData,
          }
        };
      }
    
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'success',
          eventData: eventData,
        }
      };
    }
    case SELF_REGISTER_PLAYER_FAILED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case CHANGE_FILTERS: {
      const { filtersData } = action;
      return {
        ...state,
        filters: filtersData,
      }
    }
    case END_ROUND_STARTED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'loading',
        }
      }
    }
    case END_ROUND_SUCCEEDED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'success',
        }
      }
    }
    case END_ROUND_FAILED: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          status: 'failed',
          error: action.error,
        }
      }
    }
    case RESET_ERROR_STATE: {
      return {
        ...state,
        current_event: {
          ...state.current_event,
          error: null,
        }
      }
    }
    case QUALIFY_PLAYER_SUCCEEDED: {
      const { qualifiedPlayer } = action;
      return {
        ...state,
        current_event: {
          ...state.current_event,
          current_round: {
            ...state.current_event.current_round,
            qualified_players: [
              ...state.current_event.current_round.qualified_players,
              qualifiedPlayer
            ]
          }
          
        }
      }
    }
    case DISQUALIFY_PLAYER_SUCCEEDED: {
      const { disqualifiedPlayer } = action;
      return {
        ...state,
        current_event: {
          ...state.current_event,
          current_round: {
            ...state.current_event.current_round,
            qualified_players: state.current_event.current_round.qualified_players.filter(player => player.id !== disqualifiedPlayer.id)
          }
          
        }
      }
    }
    case ENTER_SCORE_SUCCEEDED: {
      const { group } = action.group;
      const winners = group.winners;
      return {
        ...state,
        current_event: {
          ...state.current_event,
          current_round: {
            ...state.current_event.current_round,
            qualified_players: [
              ...state.current_event.current_round.qualified_players,
              ...winners.filter(winner => !state.current_event.current_round.qualified_players.some(player => player.id === winner.id))
            ],
          }
        }
      }
    }
    default:
      return state
  }
}