import { Groups } from "./groups";
import { Tab } from "react-bootstrap";

export const SuperiorGroups = ({stage, round, groups} ) => {
  const readOnly = stage > 3
  if (stage > 2 && round.rules !== 'brackets') 
    return <Tab.Pane className='event-tab-content play' eventKey={3}>
        <Groups pastRound={round} readOnly={readOnly} isCurrent={stage===3} groups={groups}></Groups>
      </Tab.Pane>
  else
    return null;
}