import { MobileBrackets } from "./mobile_brackets";
import { Brackets } from "./brackets";
import { Tab } from "react-bootstrap";

export const BracketTable = ({stage}) => {
  const isMobile = window.innerWidth < 768
  if (stage > 3)
    return <Tab.Pane className='event-tab-content play' eventKey={4}>
        {isMobile ? <MobileBrackets/> : <Brackets/>}
      </Tab.Pane>
  else
    return null;
}