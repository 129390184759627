import { SERVER_URL } from '../server_config';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const POTENTIAL_MANAGER_SUCCESS = 'POTENTIAL_MANAGER_SUCCESS';
export const POTENTIAL_MANAGER_FAILURE = 'POTENTIAL_MANAGER_FAILURE';
export const POTENTIAL_MANAGER_REQUEST = 'POTENTIAL_MANAGER_REQUEST';
export const POTENTIAL_MANAGER_RESET = 'POTENTIAL_MANAGER_RESET';

// Action Creators
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});

export const registerRequest = () => ({
  type: REGISTER_REQUEST,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const potentialManagerSuccess = (user) => ({
  type: POTENTIAL_MANAGER_SUCCESS,
  payload: user,
});

export const potentialManagerFailure = (error) => ({
  type: POTENTIAL_MANAGER_FAILURE,
  payload: error,
});

export const potentialManagerRequest = () => ({
  type: POTENTIAL_MANAGER_REQUEST,
});

export const potentialManagerReset = () => ({
  type: POTENTIAL_MANAGER_RESET,
});


// Thunk Action
export const login = (cred) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    fetch(`${SERVER_URL}/users/login`, {
    method: "POST",
    headers: {
      "Content-type": "application/json", 
    },
    credentials: 'include',
    body: JSON.stringify(cred),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.message);
        });
      }
    })
    .then((data) => {
      const user = data.user;
      dispatch(loginSuccess(user));
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    });
  }
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(logoutRequest());

    fetch(`${SERVER_URL}/users/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          dispatch(logoutSuccess());
        } else {
          return response.json().then((errorData) => {
            throw new Error(errorData.error);
          });
        }
      })
      .catch((error) => {
        dispatch(logoutFailure(error.message));
      });
  };
};

export const register = (user) => {
  return async dispatch => {
    dispatch(registerRequest());
    fetch(`${SERVER_URL}/users`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(user),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
    })
    .then((data) => {
      const user = data.user;
      dispatch(registerSuccess(user));
    })
    .catch((error) => {
      // Handle fetch errors or errors from the server
      // Dispatch a failure action or set an error message
      dispatch(registerFailure(error.message));
    });
  }
}

export const checkAuth = () => {
  return async (dispatch) => {
    fetch(`${SERVER_URL}/users/check_auth`, {
      method: "GET",
      headers: {
        "Content-type": "application/json"
      },
      credentials: "include",
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
    })
    .then((data) => {
      const user = data.user;
      dispatch(loginSuccess(user));
    })
    .catch((error) => {
      dispatch(loginFailure(error.message));
    }); 
  }
}

export const getManager = (phone_number) => {
  return async (dispatch) => {
    const url = `${SERVER_URL}/users?phone_number=${phone_number}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json"
      },
      credentials: "include",
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then((errorData) => {
          throw new Error(errorData.error);
        });
      }
      }
    ).then((response) => { 
      dispatch(potentialManagerSuccess(response.user));
    })
    .catch((error) => {
      dispatch(potentialManagerFailure(error.message));
    });
  }
}

export const resetPotentialManager = () => {
  return async (dispatch) => {
    dispatch(potentialManagerReset());
  }
}
