import { useState } from "react"
import { useDispatch } from "react-redux"
import { editCustomer } from "../../actions/customer"
import { Col, Container, Row, Form, Button } from "react-bootstrap"
import IconButton from "../shared/icon_button"

export const CustomerInfoForm = ({customer}) => {

  const [ editing, setEditing ] = useState(false)
  const [ name, setName ] = useState(customer.name)
  const [ address, setAddress ] = useState(customer.address)

  const dispatch = useDispatch()

  const handleEditCustomer = () => {
    dispatch(editCustomer(customer.id, name, address))
    setEditing(false)
  }

  return(
    <>
    {
    editing 
    ?
      <Container flush>
          <Form.Control className="margin-left-1 margin-top-1 margin-bottom-1"  size="lg"  value={name}onChange = {(e) => {setName(e.target.value)}}/>
          <Form.Control className="margin-left-1 margin-bottom-1" size="lg" value={address} onChange = {(e) => {setAddress(e.target.value)}}/>
          <Container flush className='d-flex justify-content-end margin-left-1'>
            <Button className="accent margin-right-d5 no-border" onClick={() => {setEditing(false)}}>Anuleaza</Button>
            <Button className="primary no-border" onClick={handleEditCustomer}>Salveaza</Button>
          </Container>
      </Container>
    :
    <>
      <Row size='sm'>
        <Col md='auto' xs='auto'><span className="xxl-text bold">{customer.name}</span></Col>
        <Col md='auto' xs='auto' className="center-content-v"><IconButton height={26} width={26} icon='edit' onClick={() => {setEditing(true)}}></IconButton></Col>
      </Row>
      <Row className='padding-bottom-1'>
        <Col md='auto'><span className="medium-text">{customer.address}</span></Col>
      </Row>  
    </>
    
  }
  </>)
}

export default CustomerInfoForm