import { EventFilters } from './event_filters'
import { MobileEventFilters } from './mobile_event_filters';
import { EventsList } from './events_list'


export const EventsRoot = () => {

  const isMobile = window.innerWidth < 768;
  return (
    <div className='home-container' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isMobile ? <MobileEventFilters/> : <EventFilters/> }
      <EventsList style={{ flex: 1 }}></EventsList>
    </div>
  );
}