import { FETCH_EVENT_DATA_SUCCEEDED } from "../../actions/events"
import { ENTER_SCORE_SUCCEEDED } from "../../actions/matches"

const initialState = {
  groups: {},
  status: 'uninitialized',
  error: null,
}

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_DATA_SUCCEEDED: {
      return {
        ...state,
        groups: action.response.groups,
        status: 'success',
      }
    }
    case ENTER_SCORE_SUCCEEDED: {
      const { group } = action.group;
      console.log(group)
      const winners = group.winners;
      const roundId = group.roundId;
      return {
        ...state,
        groups: {
          ...state.groups,
          [roundId]: state.groups[roundId].map(g => g.id === group.id ? group : g),
        },
      };
    }
    default: {
      return state
    }
  }
}