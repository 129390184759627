
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export const GroupLeaderboard = ({ players }) => {
  return (
    <div className="group-leaderboard">
      <TableContainer className='padding-1'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nume</TableCell>
              <TableCell align="center">SC</TableCell>
              <TableCell align="center">SP</TableCell>
              <TableCell align="center">Victorii</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player, index) => (
              <TableRow key={index}>
                <TableCell>{player.name}</TableCell>
                <TableCell align="center">{player.won_sets}</TableCell>
                <TableCell align="center">{player.lost_sets}</TableCell>
                <TableCell align="center">{player.won_matches}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}