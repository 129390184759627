import { Form, Button, Row, Col } from 'react-bootstrap';
import { register } from '../../actions/users'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo_white.png';

export const Register = () => {

  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [city, setCity] = useState();
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const alphaPattern = /^[a-zA-Z\s]+$/;
  const phonePattern = /^(02\d{8}|07\d{8})$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordPattern = /^.{6,}$/;
  const isMobile = window.innerWidth < 768;

  const validationChecks = [
    { condition: !alphaPattern.test(first_name), field: 'first_name', message: 'Prenumele poate conține doar litere' },
    { condition: !alphaPattern.test(last_name), field: 'last_name', message: 'Numele poate conține doar litere' },
    { condition: !phonePattern.test(phone), field: 'phone', message: 'Număr de telefon invalid' },
    { condition: !emailPattern.test(email), field: 'email', message: 'Adresă de email invalidă' },
    { condition: !alphaPattern.test(city), field: 'city', message: 'Orasul poate contine doar litere' },
    { condition: !passwordPattern.test(password), field: 'password', message: 'Parola trebuie să aibă minim 6 caractere' },
    { condition: password !== confirmPassword, field: 'password_confirmation', message: 'Parolele trebuie să fie identice' },
  ];

  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Check if the user is authenticated
  const shouldRedirectToDashboard = useSelector((state) => state.auth.shouldRedirectToDashboard); // Check the flag for redirection

  const validate = () => {
    var temp_errors = {}
    validationChecks.forEach(({ condition, field, message }) => {
      if (condition) {
        temp_errors[field] = message;
      }
    });
    setErrors(temp_errors)
    setValidated(Object.keys(errors).length === 0)
    return Object.keys(temp_errors).length === 0;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const validInput = validate()
    
    const user = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      password: password,
      city: city,
    }
  
    if(validInput) dispatch(register(user));
  }

  useEffect(() => {
    if (isAuthenticated && shouldRedirectToDashboard) {
      navigate('/');
    }
  }, [isAuthenticated, shouldRedirectToDashboard, navigate]);

  return (
    <Row className='register-container'>
      {!isMobile && <Col className='image-container'>
        <img src={logo} alt='logo'/>
      </Col>}
      <Col className='center-content'>
      <Form validated={validated} className='register-form'>
        <div className={`xxl-text ${isMobile ? 'white border-bottom' : 'primary-color border-bottom-dark-1'} padding-bottom-d5 margin-bottom-1 `}>
          Inregistrare
        </div>
        <Row className='primary-color'>
          <Col xs={12}>
            <Form.Label className='label large-text'>Prenume</Form.Label>
            <Form.Control size='md' required className={`input ${errors['first_name'] ? 'invalid' : ''}`} onChange = {(e) => {setFirstName(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['first_name']}
            </p>

            <Form.Label className='label large-text'>Nume</Form.Label>
            <Form.Control size='md' required className={`input ${errors['last_name'] ? 'invalid' : ''}`} onChange = {(e) => {setLastName(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['last_name']}
            </p>

            <Form.Label className='label large-text'>Adresa de Email</Form.Label>
            <Form.Control size='md' required className={`input ${errors['email'] ? 'invalid' : ''}`} onChange = {(e) => {setEmail(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['email']}
            </p>

            <Form.Label className='label large-text'>Oras</Form.Label>
            <Form.Control size='md' required className={`input ${errors['city'] ? 'invalid' : ''}`} onChange = {(e) => {setCity(e.target.value)}}></Form.Control>
            
            <p className='error-message'>
              {errors['city']}
            </p>
          </Col>
          <Col xs={12}>
            <Form.Label className='label large-text' >Numar de telefon</Form.Label>
            <Form.Control size='md' required className={`input ${errors['phone'] ? 'invalid' : ''}`} onChange = {(e) => {setPhone(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['phone']}
            </p>
            
            <Form.Label className='label large-text' >Parola</Form.Label>
            <Form.Control size='md' required className={`input ${errors['password'] ? 'invalid' : ''}`} type='password' onChange = {(e) => {setPassword(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['password']}
            </p>

            <Form.Label className='label large-text' >Confirmare Parola</Form.Label>
            <Form.Control size='md' required className={`input ${errors['password_confirmation'] ? 'invalid' : ''}`} type='password' onChange = {(e) => {setConfirmPassword(e.target.value)}}></Form.Control>
            <p className='error-message'>
              {errors['password_confirmation']}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Button type='submit' className='submit-accent bold' onClick={handleSubmit}>Creare Cont</Button>
          </Col>
        </Row>
      </Form>
    </Col>
  </Row>
  )
}