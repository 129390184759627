import { useSelector } from "react-redux"
import { selectEventData } from "../../../../selectors/events"
import { ListGroup, ListGroupItem, Row, Col, Button } from "react-bootstrap"
import { useState } from "react"
import { PlayGroup } from "./group"
import { endGroupStage, secondGroupStage } from "../../../../actions/rounds"
import { useDispatch } from "react-redux"
import { qualifyPlayer, disqualifyPlayer } from "../../../../actions/players"
import { HigherGroupsModal } from "./higher_groups_modal"
import { EditPairingsForm } from "../pairings_tab/edit"
import { selectCurrentRound } from "../../../../selectors/round"

export const Groups = ({pastRound, readOnly, isCurrent, groups}) => {
  
  const event = useSelector(selectEventData);
  const currentRound = useSelector(selectCurrentRound)

  const round = isCurrent ? currentRound : pastRound
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const qualifiedPlayers = round.qualified_players

  const canContinue = qualifiedPlayers.length >= round.nr_q_players

  console.log(groups)
  const handleSelect = (player) => {
    if (isQualified(player)) {
      dispatch(disqualifyPlayer(player))
    }
    else {
      dispatch(qualifyPlayer(player))
    }
  }

  const handleNextRound = () => {
    if(canContinue) {
      setShow(true);
    }
  }

  const handleSecondGroupsRound = (higherGroups, numberOfGroups, numberOfQualifyingPlayers) => {

    if (higherGroups) {
      const round = {
        nr_of_groups: Number(numberOfGroups),
        nr_q_players: Number(numberOfQualifyingPlayers),
        rules: 'snake',
        eventId: event.id,
      }
      dispatch(secondGroupStage(round));
    }
    else
      dispatch(endGroupStage());

    setShow(false);
  }

  const isQualified = (player) => {
    const playerIdToCheck = player.id;

    return qualifiedPlayers.some(qualifiedPlayer => qualifiedPlayer.id === playerIdToCheck);
  }

  return( groups && (
    round.groups_confirmed ?
    <>
      <p className="xxl-text extra-bold primary-color">Runda {event.rounds.length}</p>
      <p className="medium-text bold border-bottom-1 padding-bottom-1">Selectati {round.nr_q_players / round.nr_of_groups} jucatorii care s-au calificat din fiecare grupa sau introduceti scoruri apasand pe butonul de sub fiecare grupa</p>
      <Row className="playground-container">
        <Col md={9} xs={12} className="playgroups-container">
          <Row className="full-width">
            {
              groups.map((group, index) => {
                return(
                  <Col md={3} xs={12} className="group-container">
                    <div className="large-text">Grupa {index+1}</div>
                    <PlayGroup 
                      group={group} 
                      index={index + 1} 
                      key={index} 
                      isQualified={isQualified} 
                      selectPlayer={(player) => handleSelect(player)}
                      readOnly={readOnly}
                    />
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <Col className='padding-1' md={3} xs={12}>
          <p className="bold medium-text">Jucatori calificati</p>
          <ListGroup>
            {
              qualifiedPlayers.map((player, index) => { 
                return(
                  <ListGroupItem key={index}>
                    <div className="medium-text">
                      {player.name}
                    </div>
                  </ListGroupItem>
                )
              })
            }
          </ListGroup>
        </Col>
      </Row>
      {
        !readOnly && 
        <Button 
          disabled={!canContinue} 
          className='pull-right primary margin-1' 
          onClick={handleNextRound}>
            Runda urmatoare
        </Button>
      }
      <HigherGroupsModal 
        nrOfPlayers={qualifiedPlayers.length} 
        show={show} 
        onClose={() => setShow(false)} handleSecondGroupsRound={handleSecondGroupsRound}
      />
    </>
    :
    <EditPairingsForm/>
  )
  )
}