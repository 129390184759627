import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IconButton from '../shared/icon_button'

const MobileEventsTable = ({events}) => {
  
  if (!events) return <div>Loading</div>
  return (
    <Table size='sm' responsive>
      <thead>
        <tr>
          <th><IconButton height={36} width={36} disabled={true} icon='calendar'/></th>
          <th>Nume</th>
          <th><IconButton height={36} width={36} disabled={true} icon='users'/></th>
          <th><IconButton height={36} width={36} disabled={true} icon='hourglass'/></th>
        </tr>
      </thead>
      <tbody>
        { events.map((event) => {
          const date = new Date(event.date);
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Months are 0-based, so add 1
          const day = date.getDate();
          
          // Extract time
          const hours = date.getUTCHours();
          const minutes = date.getUTCMinutes();

          const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
          const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

          return (
            <tr key={event.id}>
              <td>{formattedDate}<br></br>{formattedTime}</td>
              <td><Link to={`events/${event.id}`}>{event.name}</Link></td>
              <td className='text-align-center'>{event.confirmedPlayers.length} / {event.max_players}</td>
              <td className='text-align-center'>{event.waitlistedPlayers.length}</td>
            </tr>
          )
        })
        }
      </tbody>
    </Table>
  )
}

export default MobileEventsTable;