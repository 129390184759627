import { fetchEventData } from "./events"
import { SERVER_URL } from '../server_config';

export const ADD_ROUND_STARTED = 'ADD_ROUND_STARTED'
export const ADD_ROUND_SUCCEEDED = 'ADD_ROUND_SUCCEEDED'
export const ADD_ROUND_FAILED = 'ADD_ROUND_FAILED'

export const EDIT_ROUND_STARTED = 'EDIT_ROUND_STARTED'
export const EDIT_ROUND_SUCCEEDED = 'EDIT_ROUND_SUCCEEDED'
export const EDIT_ROUND_FAILED = 'EDIT_ROUND_FAILED'

export const END_ROUND_STARTED = 'END_ROUND_STARTED'
export const END_ROUND_SUCCEEDED = 'END_ROUND_SUCCEEDED'
export const END_ROUND_FAILED = 'END_ROUND_FAILED'

export const addRoundStarted = () => ({
  type: ADD_ROUND_STARTED,
})

export const addRoundSucceeded = groups => ({
  type: ADD_ROUND_SUCCEEDED,
  groups
})

export const editRoundFailed = error => ({
  type: ADD_ROUND_FAILED,
  error,
})

export const editRoundStarted = () => ({
  type: ADD_ROUND_STARTED,
})

export const editRoundSucceeded = groups => ({
  type: ADD_ROUND_SUCCEEDED,
  groups
})

export const addRoundFailed = error => ({
  type: ADD_ROUND_FAILED,
  error,
})

export const endRoundStarted = () => ({
  type: END_ROUND_STARTED,
})

export const endRoundSucceeded = () => ({
  type: END_ROUND_SUCCEEDED,
})

export const endRoundFailed = error => ({
  type: END_ROUND_FAILED,
  error,
})


export const addRound = (round) => {
  return async (dispatch) => {
    const body = round;
    dispatch(addRoundStarted())

    try {
      const response = await fetch(`${SERVER_URL}/rounds`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      const parsed_response = await response.json();

      dispatch(addRoundSucceeded(parsed_response.data));
      dispatch(fetchEventData(round.eventId));
    } catch (error){
      dispatch(addRoundFailed(error));
    }
  }
}
export const secondGroupStage = (round) => {
  return async (dispatch, getState) => {
    
    dispatch(addRoundStarted())
    const state = getState();
    const body = round
    const roundId = state.events.current_event.current_round.id

    try {
      const response = await fetch(`${SERVER_URL}/rounds/${roundId}/second_group_stage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      const updatedEvent = await response.json();

      dispatch(addRoundSucceeded(updatedEvent.event));
      dispatch(fetchEventData(round.eventId));
      
    } catch (error){
      dispatch(addRoundFailed(error));
    }
  }
}

export const deleteRound = () => {
  return async (dispatch, getState) => {
    
    const state = getState();

    const eventId = state.events.current_event.eventData.id;

    const roundId = state.events.current_event.current_round.id;

    dispatch(editRoundStarted())

    try {
      const response = await fetch(`${SERVER_URL}/rounds/${roundId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      await response.json();

      dispatch(fetchEventData(eventId));
    } catch (error){
      dispatch(addRoundFailed(error));
    }
  }
}

export const endRound = () => {
  return async (dispatch, getState) => {
    
    dispatch(endRoundStarted())
    const state = getState();

    const roundId = state.events.current_event.current_round.id

    try {
      const response = await fetch(`${SERVER_URL}/rounds/${roundId}/end_round`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      dispatch(fetchEventData(state.events.current_event.eventData.id));

    } catch (error){
      dispatch(editRoundFailed(error));
    }
  }
}

export const endGroupStage = () => {
  return async (dispatch, getState) => {
    
    dispatch(endRoundStarted())
    const state = getState();

    const roundId = state.events.current_event.current_round.id

    try {
      const response = await fetch(`${SERVER_URL}/rounds/${roundId}/end_group_stage`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      dispatch(endRoundSucceeded())
      dispatch(fetchEventData(state.events.current_event.eventData.id));

    } catch (error){
      dispatch(editRoundFailed(error));
    }
  }
}


