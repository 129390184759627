import { Groups } from "./groups";
import { Tab } from "react-bootstrap";

export const InferiorGroups = ({stage, round, groups} ) => {

  const readOnly = stage > 3
  if (stage > 1) 
    return <Tab.Pane className='event-tab-content play' eventKey={2}>
        <Groups pastRound={round} readOnly={readOnly} isCurrent={stage === 2} groups={groups}/>
      </Tab.Pane>
  else
    return null;
}