import {
  FETCH_EVENT_DATA_STARTED,
  FETCH_EVENT_DATA_SUCCEEDED,
  FETCH_EVENT_DATA_FAILED,
} from '../../actions/events';

import {
  ADD_PLAYER_STARTED,
  ADD_PLAYER_SUCCEEDED,
  ADD_PLAYER_FAILED,
  MOVE_PLAYER_SUCCEEDED,
  DELETE_PLAYER_SUCCEEDED,
  DELETE_PLAYER_FAILED,
} from '../../actions/players';

const initialState = {
  status: 'uninitialized',
  confirmedPlayers: [],
  waitlistedPlayers: [],
  rejectedPlayers: [],
}

export default function players(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENT_DATA_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case FETCH_EVENT_DATA_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        confirmedPlayers: action.response.confirmedPlayers,
        waitlistedPlayers: action.response.waitlistedPlayers,
        rejectedPlayers: action.response.rejectedPlayers,
      }
    }
    case FETCH_EVENT_DATA_FAILED: {
      return {
        ...state,
        status: 'failed',
        events: [],
        error: action.error
      }
    }
    case ADD_PLAYER_STARTED: {
      return {
        ...state,
        status: 'loading'
      }
    }
    case ADD_PLAYER_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        confirmedPlayers: action.response.confirmedPlayers,
        waitlistedPlayers: action.response.waitlistedPlayers,
        rejectedPlayers: action.response.rejectedPlayers,
      }
    }
    case ADD_PLAYER_FAILED: {
      return {
        ...state,
        status: 'failed',
        error: action.error
      }
    }
    case MOVE_PLAYER_SUCCEEDED : {
      console.log(action)
      return {
        ...state,
        status: 'success',
        confirmedPlayers: action.data.confirmedPlayers,
        waitlistedPlayers: action.data.waitlistedPlayers,
        rejectedPlayers: action.data.rejectedPlayers,
      }
    }
    case DELETE_PLAYER_FAILED: {
      return {
        ...state,
        status: 'failed',
        error: action.error
      }
    }
    case DELETE_PLAYER_SUCCEEDED: {
      return {
        ...state,
        status: 'success',
        confirmedPlayers: action.data.confirmedPlayers,
        waitlistedPlayers: action.data.waitlistedPlayers,
        rejectedPlayers: action.data.rejectedPlayers,
      }
    }
    default:
      return state;
  }
}