import { Col} from "react-bootstrap"
import ReactQuill from "react-quill"

export const EventInfo = ({event}) => {
  return (
    <Col xs={12} md={4} className="detailed-event-column">
      <div className="xxl-text bold primary-color">{event.name}</div>
      <div className="medium-text primary-color margin-top-1 border-bottom-1 padding-bottom-1">{event.location} | {event.date.slice(0,10)} - {event.date.slice(11,16)}</div>

      <ReactQuill
        value={event.description}
        theme='bubble'
        readOnly>
      </ReactQuill>
    </Col>
  )
}