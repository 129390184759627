import { Modal } from "react-bootstrap"
import { Match } from "./match"
import {Tab, Tabs} from '@mui/material'
import { useState } from "react"
import { GroupLeaderboard } from "./group_leaderboard"



export const ScoresModal = ({show, handleClose, index, matches, players, readOnly}) => {

  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Grupa {index}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="Scoruri"></Tab>
          <Tab label="Clasament"></Tab>
        </Tabs>
        {activeTab === 0 && <div>
          {matches.map((match) => (
            <Match match={match} readOnly={readOnly}/>
          ))}
        </div>
        }
        {activeTab === 1 && <div>
          <GroupLeaderboard players={players}/>
        </div>}
      </Modal.Body>
    </Modal>
  );
} 