import { ListGroup, ListGroupItem } from "react-bootstrap"

export const Winner = ({player}) => {
  return(
    <div className="bracket-container">
    <p className={`bold large-text`}>Castigator</p>
    <ListGroup className="group">
      <ListGroupItem  className='selected'>
        <div className="medium-text">
          {player.name}
        </div>
        <div className="pull-right small-text">
          {player.city}
        </div>
      </ListGroupItem>
    </ListGroup>
    </div>
  )
}