import { useSelector } from "react-redux";
import { selectEventData } from "../../../../selectors/events";
import { selectRounds, selectCurrentRound } from "../../../../selectors/round";
import { Bracket } from "./bracket";
import { Winner } from "./winner";
import { Carousel } from "react-bootstrap";
import { useState } from "react";

export const MobileBrackets = () => {
  
  const event = useSelector(selectEventData);
  const rounds = useSelector(selectRounds);
  const currentRound = useSelector(selectCurrentRound);
  const bracket_rounds = rounds.filter(round => round.rules === 'brackets')
  const [index, setIndex] = useState(bracket_rounds.findIndex(round => round.id === currentRound.id))


  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  
  const finished_event = event.stageId === 5
  
  return (
      <div className='brackets-container'>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          variant='dark'
          indicators={false}
          controls={false}>
          {bracket_rounds.map((round, index) => (
            <Carousel.Item key={round.id}>
              <Bracket
                round={round.id === currentRound.id ? currentRound : round}
                index={index}
                isCurrentRound={round.id === currentRound.id && !finished_event}
              ></Bracket>
            </Carousel.Item>
          ))}
        </Carousel>
        {finished_event && (
          <Winner player={rounds[rounds.length - 1].qualified_players[0]}></Winner>
        )}
      </div>
    );

};

