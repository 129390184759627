import { Button, Row } from "react-bootstrap"
import { endRound } from "../../../../actions/rounds"
import { useDispatch, useSelector } from "react-redux"
import { BracketPlayGroup } from "./bracket_group"
import { selectAllGroups } from "../../../../selectors/groups"

export const Bracket = ({round, index, isCurrentRound}) => {

  const qualifiedPlayers = round.qualified_players;
  const dispatch = useDispatch();
  const groups = useSelector(selectAllGroups);

  const canContinue = qualifiedPlayers.length === round.nr_q_players;

  const isQualified = (player) => {
    return qualifiedPlayers.some(qualifiedPlayer => qualifiedPlayer.id === player.id);
  }

  const handleNextRound = () => {
    if(canContinue) {
      dispatch(endRound());
    }
  }

  return(
    <div className="bracket-container">
      <div className={`${isCurrentRound ? 'bold' : 'secondary-color'} medium-text`}>Runda {index + 2}</div>
      {
        groups[round.id].map(group => (<BracketPlayGroup group={group} isQualified={isQualified} disabled={!isCurrentRound} match={group.matches[0]} readOnly={false}></BracketPlayGroup>))
      }
      <Row>
        {isCurrentRound && canContinue && <Button className="margin-top-2 primary" onClick={handleNextRound}>Runda urmatoare</Button>}
      </Row>

    </div>
  )
}