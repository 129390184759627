import { InputGroup, Form } from "react-bootstrap"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { enterScore } from "../../../../actions/matches"
import { ButtonGroup, Button } from "@mui/material"

export const Match = ({match, readOnly}) => {

  const [player1Score, setPlayer1Score] = useState(match.player_1_score)
  const [player2Score, setPlayer2Score] = useState(match.player_2_score)
  const [scoresConfirmed, setScoresConfirmed] = useState(match.player_1_score != null && match.player_2_score != null)

  const dispatch = useDispatch()

  const handleChangeFirstScore = (event) => {
    const enteredScore = event.target.value
    
    if (!isNaN(enteredScore) && enteredScore < 4) {
      setPlayer1Score(enteredScore) 
    }
  }

  const handleChangeSecondScore = (event) => {
    const enteredScore = event.target.value
    if (!isNaN(enteredScore) && enteredScore < 4) {
      setPlayer2Score(enteredScore) 
    }
  }

  const setScore = (player1Score, player2Score) => {
    setPlayer1Score(player1Score)
    setPlayer2Score(player2Score)
    dispatch(enterScore(match.id, player1Score, player2Score)).then(() => setScoresConfirmed(true))
  }

  useEffect(() =>
  {
    if (player1Score && player2Score && (player1Score < player2Score || player1Score > player2Score) && !match.finished)
      dispatch(enterScore(match.id, player1Score, player2Score)).then(() => setScoresConfirmed(true))
  },
  [player1Score, player2Score])
  
  return (
    <>
      <InputGroup className={`padding-1 ${scoresConfirmed ? 'confirmed-score' : ''}`}>
        <InputGroup.Text className="padding-1 match-player-name-left">{match.player1.name}</InputGroup.Text>
        <Form.Control size='sm' readOnly={readOnly} className="text-center" onChange={handleChangeFirstScore} value={player1Score} maxLength={1}/>
        <Form.Control size='sm' readOnly={readOnly} className="text-center" onChange={handleChangeSecondScore} value={player2Score} maxLength={1}/>
        <InputGroup.Text className="padding-1 match-player-name-right">{match.player2.name}</InputGroup.Text>
      </InputGroup>
      {!readOnly && <ButtonGroup className="center-content-h">
        <Button onClick={() => setScore(3,0)}>3-0</Button>
        <Button onClick={() => setScore(3,1)}>3-1</Button>
        <Button onClick={() => setScore(3,2)}>3-2</Button>
        <Button onClick={() => setScore(2,3)}>2-3</Button>
        <Button onClick={() => setScore(1,3)}>1-3</Button>
        <Button onClick={() => setScore(0,3)}>0-3</Button>
      </ButtonGroup>}
    </>
  )
}