import { Carousel, Button, Row, Col } from "react-bootstrap"
import { useState } from "react"
import { addRound } from "../../../../actions/rounds";
import { useDispatch } from "react-redux";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";


export const NewPairingsForm = ({nrOfPlayers, eventId}) => {

  const [ nrOfGroups, setNumberOfGroups] = useState();
  const [ numberOfQualifyingPlayers, setNumberOfQualifyingPlayers] = useState();
  const [ rules, setRules ] = useState('snake')
  const [index, setIndex] = useState(0);
  
  const dispatch = useDispatch();

  const getGroupNrOptions = (number) => {
    if (number <= 0) {
      return []; // Return an empty array for non-positive numbers
    }
  
    const highestGroupNr = Math.floor(number / 2);

    const results = {
      1: '1 (Liga)',
    };
  
    for (let i = 2; i <= highestGroupNr; i += 1) {
      results[i] = i.toString();
    }

    results[highestGroupNr] = `${highestGroupNr} (Turneu)`
  
    return results;
  }

  const getQualifyingPlayerOptions = (nrOfPlayers) => {
    const results = {}
    const maxPlayers = nrOfPlayers % nrOfGroups === 0 ? (nrOfPlayers / nrOfGroups) - 1 : (nrOfPlayers / nrOfGroups);

    for (let i = 1; i <= maxPlayers; i += 1) {
      results[i] = i.toString();
    }
    return results
  }

  const isValid = nrOfGroups != null && numberOfQualifyingPlayers != null;
  const nrOfGroupOptions = getGroupNrOptions(nrOfPlayers);
  const nrOfQualifyingPlayerOptions = getQualifyingPlayerOptions(nrOfPlayers);
  const ruleOptions = {
    'snake' : 'Regula sarpelui',
    'random' : 'La intamplare',
  }

  const handleChooseGroupOption = (e) => {
    setNumberOfGroups(e.currentTarget.value);
    setIndex(1);
  }

  const handleChooseQualifyingPlayerOption = (e) => {
    setNumberOfQualifyingPlayers(e.currentTarget.value);
    setIndex(2);
  }

  const handleChooseOfRules =(e) => {
    setRules(e.currentTarget.value);
  }

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleSubmit = () => {
    const round = {
      nr_of_groups: Number(nrOfGroups),
      nr_q_players: Number(numberOfQualifyingPlayers),
      rules: rules,
      eventId: eventId,
    }
    if (isValid)
      dispatch(addRound(round));
  }

  const SubmitPairingsButton = () => {
    if(isValid)
      return(
        <Button onClick={handleSubmit} className="primary">
          Creeaza tragerea la sorti
        </Button>
      )
  }
  return(
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} className='custom-carousel' variant='dark' interval={null}>
        <Carousel.Item className="custom-slide">
          <div className="slide-container">
            <FormControl>
              <FormLabel className="large-text text-center">In cate grupe doriti sa fie impartiti cei {nrOfPlayers} Jucatori?</FormLabel>
              <br></br>
              <RadioGroup
                row
                value={nrOfGroups}
                onChange={handleChooseGroupOption}
                style={{ display: "flex", justifyContent: "center" }}>
                {Object.keys(nrOfGroupOptions).map((option, index) => {
                  return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={nrOfGroupOptions[option]}
                    labelPlacement="top" />
                  )
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <Carousel.Caption>
            <SubmitPairingsButton></SubmitPairingsButton>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="custom-slide">
          <div className="slide-container">
            <FormControl>
              <FormLabel className="large-text text-center">Cati dintre jucatori se califica din fiecare grupa?</FormLabel>
              <br></br>
                <RadioGroup
                row
                value={numberOfQualifyingPlayers}
                onChange={handleChooseQualifyingPlayerOption}
                style={{ display: "flex", justifyContent: "center" }}>
                {Object.keys(nrOfQualifyingPlayerOptions).map((option) => {
                  return (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={nrOfQualifyingPlayerOptions[option]}
                    labelPlacement="top"
                    style={{ margin: "0 10px" }}
                  />
                  );
                })}
                </RadioGroup>
            </FormControl>
          </div>
          <Carousel.Caption>
            <SubmitPairingsButton></SubmitPairingsButton>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="custom-slide">
          <div className="slide-container">
            <FormControl>
              <FormLabel className="large-text text-center">In ce sistem doriti sa fie create grupele?</FormLabel>
              <br></br>
              <RadioGroup
                row
                value={rules}
                onChange={handleChooseOfRules}
                style={{ display: "flex", justifyContent: "center" }}>
                {Object.keys(ruleOptions).map((option) => {
                  return (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={ruleOptions[option]}
                    labelPlacement="top"
                    style={{ margin: "0 10px" }}
                  />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <Carousel.Caption>
            <SubmitPairingsButton></SubmitPairingsButton>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  )

}