import { configureStore } from '@reduxjs/toolkit'
import { logger } from 'redux-logger';
import events from './reducers/event'
import authReducer from './reducers/authentication'
import customerReducer from './reducers/customer'
import { persistStore, persistReducer } from 'redux-persist'; // Import persistStore
import persistConfig from './persistConfig'; // Import the persistConfig
import { fetchEvents } from '../actions/events';
import playersReducer from './reducers/players';
import { groupsReducer } from './reducers/groups';

import { combineReducers } from 'redux'; 

const rootReducer = combineReducers({
  events: events,
  auth: authReducer,
  customer: customerReducer,
  players: playersReducer,
  groups: groupsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

const persistor = persistStore(store, {}, () => {
  // This callback is executed after rehydration is complete
  store.dispatch(fetchEvents());
});

export { store, persistor }