import { useSelector } from "react-redux";
import { selectEventData } from "../../../../selectors/events";
import { selectRounds, selectCurrentRound } from "../../../../selectors/round";
import { Bracket } from "./bracket";
import { Winner } from "./winner";

export const Brackets = () => {
  
  const event = useSelector(selectEventData);
  const rounds = useSelector(selectRounds);
  const currentRound = useSelector(selectCurrentRound);
  const bracket_rounds = rounds.filter(round => round.rules === 'brackets')

  const finished_event = event.stageId === 5 
  
  return (
    <div className='brackets-container'>
      {
        bracket_rounds.map((round, index) => {return (
          <Bracket key={round.id} round={round.id === currentRound.id ? currentRound : round} index={index} isCurrentRound={round.id === currentRound.id && !finished_event}></Bracket>
        )}
        )
      }
      { finished_event && <Winner player={rounds[rounds.length-1].qualified_players[0]}></Winner>}
    </div>
  );

};

