import { ListGroup, ListGroupItem } from "react-bootstrap"
import { ScoresModal } from "./scores_modal";
import { useState } from "react"


export const PlayGroup = ({group, index, isQualified, selectPlayer, disabled, readOnly}) => {

  const max_players = group.nr_q_players;
  const qualified_players = group.instances.filter(isQualified)

  const [ qCount, setQCount ] = useState(qualified_players.length);
  const [ scoresOpen, setScoresOpen ] = useState(false);

  const handleSelect = (player) => {
    if (isQualified(player)) {
      setQCount(qCount - 1);
      selectPlayer(player);
    }
    else if(qCount < max_players) {
      setQCount(qCount + 1);
      selectPlayer(player);
    }
  }

  return(
    <>
      <ListGroup className="group">
        {group.instances.map(player =>
          <ListGroupItem  disabled={disabled || readOnly} className={isQualified(player) ? 'selected' : ''} action={!disabled} onClick={() => handleSelect(player)}>
            <div className="medium-text">
              {player.name}
            </div>
            <div className="pull-right small-text">
              {player.city}
            </div>
          </ListGroupItem>
      )}
      </ListGroup>
      <ListGroup className="margin-bottom-1">
          <ListGroupItem action={true} onClick={() => {setScoresOpen(!scoresOpen)}}>
          <div className="medium-text bold">Scoruri introduse: {group.finished_match_count} / {group.total_match_count}</div>
        </ListGroupItem>
      </ListGroup>
      <ScoresModal show={scoresOpen} readOnly={readOnly} handleClose={() => {setScoresOpen(false)}} groupId={group.id} index={index} matches={group.matches} players={group.instances}/>
    </>
  )
}