import { SERVER_URL } from '../server_config';

export const ENTER_SCORE_SUCCEEDED = 'ENTERS_SCORE_SUCCEEDED';

const enterScoreSucceeded = (group) => {
  return {
    type: ENTER_SCORE_SUCCEEDED,
    group,
  }
}
export const enterScore = (matchId, score1, score2) => {
  return async (dispatch) => {

    const body = {score1, score2}
    const url = `${SERVER_URL}/matches/${matchId}/enter_score`

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');

      const group = await response.json()
      dispatch(enterScoreSucceeded(group))
    } catch (error){
      console.log(error)
    }
  }
}