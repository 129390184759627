import { SERVER_URL } from '../server_config';

export const FETCH_EVENTS_STARTED = 'FETCH_EVENTS_STARTED'
export const FETCH_EVENTS_SUCCEEDED = 'FETCH_EVENTS_SUCCEEDED'
export const FETCH_EVENTS_FAILED = 'FETCH_EVENTS_FAILED'
export const FETCH_EVENT_DATA_STARTED = 'FETCH_EVENT_DATA_STARTED'
export const FETCH_EVENT_DATA_SUCCEEDED = 'FETCH_EVENT_DATA_SUCCEEDED'
export const FETCH_EVENT_DATA_FAILED = 'FETCH_EVENT_DATA_FAILED'
export const MOVE_PLAYER_FAILED = 'MOVE_PLAYER_FAILED'
export const MOVE_PLAYER_SUCCEEDED = 'MOVE_PLAYER_SUCCEEDED'
export const MOVE_PLAYER_STARTED = 'MOVE_PLAYER_STARTED'
export const SELF_REGISTER_PLAYER_SUCCEEDED = 'SELF_REGISTER_PLAYER_SUCCEEDED'
export const SELF_REGISTER_PLAYER_FAILED = 'SELF_REGISTER_PLAYER_FAILED'

export const CHANGE_FILTERS = 'CHANGE_FILTERS'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'

export const fetchEventsStarted = () => ({
  type: FETCH_EVENTS_STARTED
})

export const fetchEventsSucceeded = events => ({
  type: FETCH_EVENTS_SUCCEEDED,
  events
})

export const fetchEventsFailed = error => ({
  type: FETCH_EVENTS_FAILED,
  error
})

export const fetchEventDataStarted = () => ({
  type: FETCH_EVENT_DATA_STARTED
})

export const fetchEventDataSucceeded = (response) => ({
  type: FETCH_EVENT_DATA_SUCCEEDED,
  response
})

export const fetchEventDataFailed = error => ({
  type: FETCH_EVENT_DATA_FAILED,
  error
})

export const changeFilters = filters => ({
  type: CHANGE_FILTERS,
  filtersData: filters,
})

export const resetErrorState = () => ({
  type: RESET_ERROR_STATE
})

export const selfRegisterPlayerSucceeded = (event) => ({
  type: SELF_REGISTER_PLAYER_SUCCEEDED,
  eventData: event
})

export const selfRegisterPlayerFailed = (error) => ({
  type: SELF_REGISTER_PLAYER_FAILED,
  error
})


export const fetchEvents = (filters = {}) => {
  return async dispatch => {
    dispatch(changeFilters(filters));
    dispatch(fetchEventsStarted());

    const queryString = Object.keys(filters)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]))
      .join('&');

    try {
      const url = `${SERVER_URL}/events` + (queryString ? `?${queryString}` : '');

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }

      const events = await response.json();
      dispatch(fetchEventsSucceeded(events.data)); // Dispatch an action with the fetched data
    } catch (error) {
      dispatch(fetchEventsFailed(error.message)); // Dispatch an action for fetch failure
    }
  };
};

export const addEvent = (event) => {
  return async dispatch => {
    fetch(`${SERVER_URL}/events`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
      credentials: "include",
    })
    .then(response => {
      if(!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
    .then (() =>{
      dispatch(fetchEvents());
    })
  }
}

export const editEvent = (eventId, updatedEvent) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}`, {
        method: 'PUT', // Use PUT to update the event
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(fetchEvents());

    } catch (error) {
      console.error('Error updating event:', error);
    }
  };
};


export const fetchEventData = (eventId) => {
  return async (dispatch) => {
    dispatch(fetchEventDataStarted());

    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const parsed_response = await response.json(); // Parse the response body as JSON

      dispatch(fetchEventDataSucceeded(parsed_response));
    } catch (error) {
      dispatch(fetchEventDataFailed(error));
    }
  }
}

export const selfRegisterPlayer = (player) => {
  return async dispatch => {
    const body = {
      player: player,
    }

    fetch(`${SERVER_URL}/players/self_register`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (response.status === 409) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => { 
      dispatch(selfRegisterPlayerSucceeded(response.eventData))
    })
    .catch(error => {
      dispatch(selfRegisterPlayerFailed(error.message))
    });
  }
}

export const confirmGroups = (players) => {
  return async (dispatch, getState) => {
    const state = getState();
    const eventId = state.events.current_event.eventData.id
    const body = {
      playersData: players,
      roundId: state.events.current_event.current_round.id
    };

    try {
      const response = await fetch(`${SERVER_URL}/groups/update_multiple`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", 
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      if (!response.ok) 
        throw new Error('Network response was not ok');
      
      const parsed_response = await response.json();

      dispatch(fetchEventData(eventId));
    } catch (error){
      console.log(error)
    }
  };
};

export const closeEventRegistrations = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/close_registrations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(fetchEventData(eventId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const openEventRegistrations = (eventId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${SERVER_URL}/events/${eventId}/open_registrations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      dispatch(fetchEventData(eventId));
    } catch (error) {
      console.log(error);
    }
  };
};
