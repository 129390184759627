import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  LOGOUT_SUCCESS,
} from '../../actions/users';

import { 
  NEW_CUSTOMER_SUCCESS
} from '../../actions/customer';

const initialState = {
  isAuthenticated: false, // Flag indicating if the user is authenticated
  user: null, // User data when authenticated
  loading: false, // Loading state during authentication requests
  error: null, // Error message if authentication fails
  shouldRedirectToDashboard: false, // Flag for redirection after successful login
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null,
        shouldRedirectToDashboard: true, // Set to true to trigger redirection
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null,
        shouldRedirectToDashboard: true, // Set to true to trigger redirection
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: action.payload,
        shouldRedirectToDashboard: false, // Set to false on login failure
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null,
        shouldRedirectToDashboard: false, // Reset on logout
      };
    case NEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        user: action.user
      }
    default:
      return state;
  }
};

export default authReducer;
