import logo from '../../images/logo_white.png'
import { Col, Row, Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { checkAuth, logout } from '../../actions/users'
import { useEffect } from 'react'
import { Footer } from './footer'

export const Header = ({children}) => {

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  const dispatch = useDispatch()

  const handleLogout = (event) => {
    event.preventDefault();
    dispatch(logout())
  }

  useEffect(() => {
    dispatch(checkAuth())
  }, [])
  

  return (
    <>
      <Container fluid >
        <Row className="header-container">
          <Col md={4} xs={4} className='center-content-v'>
            <a 
              className='header-link large-text' 
              href={isAuthenticated ? (user.role === 'customer_manager' ? `/customers/${user.customerId}` : '/customers/new') : '/login'}>
              {isAuthenticated ? (user.role === 'customer_manager' ? 'Organizatia Mea' : 'Creeaza\nTurneu') : 'Creeaza\nOrganizatie'}
            </a>
          </Col>
          <Col md={4} xs={4} className='center-content'>
            <a className='header-link' href='/'>
              <img src={logo} className='logo'></img>
            </a>
          </Col>
          <Col md={4} xs={4} className='center-content-v'>
            { isAuthenticated ? 
              <a className='header-link large-text' href='#' onClick={handleLogout} >Logout</a> 
              :
              <div className='login-register-container'>
                <a className='header-link large-text' href='/login'>Logare</a>
                <a className='header-link large-text' href='/register'>Inregistrare</a>
              </div>
            }
          </Col>
        </Row>
      </Container>
      {children}
      <Footer></Footer>
    </>
  )
}
