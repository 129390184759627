import { Tab, Nav, Button } from "react-bootstrap"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchEventData } from "../../../actions/events"
import { selectEventData, selectEventDataStatus } from "../../../selectors/events"
import { useParams } from 'react-router-dom';
import { LoadingPage } from "../../shared/loading_page"
import EventForm from "../form/event_form"
import { PlayersTab } from "./players_tab/root"
import { closeEventRegistrations, openEventRegistrations } from "../../../actions/events"
import { Tooltip } from "@mui/material"
import { NewPairingsForm } from "./pairings_tab/new"
import { selectConfirmedPlayers, selectRejectedPlayers, selectWaitlistedPlayers } from "../../../selectors/players"
import { SuperiorGroups } from "./play_tab/superior_groups"
import { InferiorGroups } from "./play_tab/inferior_groups"
import { BracketTable } from "./play_tab/bracket_table"
import { selectInferiorGroupsRound, selectSuperiorGroupsRound } from "../../../selectors/round"
import { selectAllGroups } from "../../../selectors/groups"

export const EventDashboard = () => {

  const status = useSelector(selectEventDataStatus)
  const event = useSelector(selectEventData)
  const confirmedPlayers = useSelector(selectConfirmedPlayers)
  const waitlistedPlayers = useSelector(selectWaitlistedPlayers)
  const rejectedPlayers = useSelector(selectRejectedPlayers)
  const inferiorGroupsRound = useSelector(selectInferiorGroupsRound)
  const superiorGroupsRound = useSelector(selectSuperiorGroupsRound)
  const allGroups = useSelector(selectAllGroups)

  const switchRegistrationStatus = () => {
    if (event.registration_closed) {
      dispatch(openEventRegistrations(event.id))
    } else {
      dispatch(closeEventRegistrations(event.id))
    }
  }

  const dispatch = useDispatch()
  const { eventId } = useParams();

  useEffect(() => {
    dispatch(fetchEventData(eventId))
  }, [dispatch])

  if (status === 'loading') return <LoadingPage/>
  if (status === 'success' || status === 'error')
    return (
      <div className="dashboard-container">
        <Tab.Container defaultActiveKey={event.stageId}>
          <Nav className='custom-navbar' activeKey={1} variant="pills">
            <Nav.Item>
              <Tooltip title='Editeaza detalii eveniment'>
                <Nav.Link eventKey={0} className="extra-bold">{event.name}</Nav.Link>
              </Tooltip>
            </Nav.Item>
            <Nav.Item>
              <Tooltip title='Editeaza lista de jucatori'>
                <Nav.Link eventKey={1}>Jucatori</Nav.Link>
              </Tooltip>
            </Nav.Item>
            { event.stageId === 1 && 
              <Nav.Item>
                <Tooltip title='Creeaza Grupele'>
                  <Nav.Link eventKey={7}>Incepe Turneul</Nav.Link>
                </Tooltip>
              </Nav.Item>
            }
            { (event.stageId > 1 && inferiorGroupsRound) &&
              <Nav.Item>
                <Tooltip title='Vizualizare grupe inferioare si introducere scoruri'>
                  <Nav.Link eventKey={2}>Grupe Inferioare</Nav.Link>
                </Tooltip>
              </Nav.Item>
            }
            { (event.stageId > 2 && superiorGroupsRound) && 
              <Nav.Item>
                <Tooltip title='Vizualizare grupe superioare si introducere scoruri'>
                  <Nav.Link eventKey={3}>Grupe Superioare</Nav.Link>
                </Tooltip>
              </Nav.Item>
            }
            { event.stageId > 3 && (
            <Nav.Item>
              <Tooltip title='Vizualizare faza eliminatorie si introducere scoruri'>
                <Nav.Link eventKey={4}>Eliminatorii</Nav.Link>
              </Tooltip>
            </Nav.Item>
            )}
            { event.stageId === 1 && 
            <Tooltip title='Organizatorii vor putea in continuare sa adauge jucatori'>
              <Button  
                className='accent no-border ml-auto margin-left-1'
                onClick={switchRegistrationStatus}
              >
                {event.registration_closed ? 'Deschidere Inscrieri' : 'Inchidere Inscrieri'}
              </Button>
            </Tooltip> }
          </Nav>
          <Tab.Content className='event-dashboard-container' >
            <Tab.Pane className='event-tab-content info' eventKey={0}>
              <div className="center-content-h">
                <EventForm event = {event}></EventForm>
              </div>
            </Tab.Pane>
            <Tab.Pane className='event-tab-content registration' eventKey={1}>
              <PlayersTab
                event = {event}
                confirmedPlayers={confirmedPlayers}
                waitlistedPlayers={waitlistedPlayers}
                rejectedPlayers={rejectedPlayers}
              />
            </Tab.Pane>
            <Tab.Pane className='event-tab-content matchups' eventKey={7}>
              <NewPairingsForm nrOfPlayers={confirmedPlayers.length} eventId={event.id}/>
            </Tab.Pane>
            <InferiorGroups stage={event.stageId} round={inferiorGroupsRound} groups={allGroups[inferiorGroupsRound?.id]} />
            <SuperiorGroups stage={event.stageId} round={superiorGroupsRound} groups={allGroups[superiorGroupsRound?.id]}/>
            <BracketTable stage={event.stageId}/>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
} 