import { Form } from "react-bootstrap"

export const CountyFilter = ({county, setCounty, labelClass='', style}) => {
  const ROMANIA_COUNTIES = [
    "Alba",
    "Arad",
    "Argeș",
    "Bacău",
    "Bihor",
    "Bistrița-Năsăud",
    "Botoșani",
    "Brăila",
    "Brașov",
    "București",
    "Buzău",
    "Călărași",
    "Caraș-Severin",
    "Cluj",
    "Constanța",
    "Covasna",
    "Dâmbovița",
    "Dolj",
    "Galați",
    "Giurgiu",
    "Gorj",
    "Harghita",
    "Hunedoara",
    "Ialomița",
    "Iași",
    "Ilfov",
    "Maramureș",
    "Mehedinți",
    "Mureș",
    "Neamț",
    "Olt",
    "Prahova",
    "Sălaj",
    "Satu Mare",
    "Sibiu",
    "Suceava",
    "Teleorman",
    "Timiș",
    "Tulcea",
    "Vâlcea",
    "Vaslui",
    "Vrancea"
  ];

  return(
    <>
      <Form.Label className={labelClass}>Judet:</Form.Label>
        <Form.Select defaultValue = {county} onChange = {(e) => {setCounty(e.target.value)}} className={style}>
        <option></option>
        {ROMANIA_COUNTIES.map((county) => {
          return(
            <option key={county}>{county}</option>
          )
        })}
      </Form.Select>
    </>
  )
}