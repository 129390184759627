import { createSelector } from 'reselect';

export const selectConfirmedPlayers = state => state.players.confirmedPlayers
export const selectWaitlistedPlayers = state => state.players.waitlistedPlayers
export const selectRejectedPlayers = state => state.players.rejectedPlayers

export const selectAllCurrentEventPlayers = createSelector(
  selectConfirmedPlayers,
  selectWaitlistedPlayers,
  selectRejectedPlayers,
  (confirmedPlayers, waitlistedPlayers, rejectedPlayers) => {
    // Combine the three selectors here
    // For example, you can return an array containing all the players
    return [...confirmedPlayers, ...waitlistedPlayers, ...rejectedPlayers];
  }
);